import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { message } from "utils/format";
import Div from "components/Div";
import P from "components/P";
import InfoBox from "components/InfoBox";
import { UploadAsset } from "components/inputs";
import AssetBundleList from "./list";
import { BoldText } from "./Styled";

const errorMessagesMap = {
  assetSize: "The file size is too big",
  assetQuantity: "The asset bundle has a maximum quantity of files allowed.",
  assetName: "The asset name can't start with $."
};

const AssetBundle = ({
  companyId,
  assetBundles = [],
  relationEntity,
  relationId,
  parentText,
  rootStore,
  themeStore,
  assetBundlesStore,
  disabled,
  onBundleCreated
}) => {
  const { validationErrors } = rootStore;
  const {
    findAssetsByBundleId,
    addAsset,
    maxAssetSizeMB,
    maxAssetQty
  } = assetBundlesStore;
  const bundleId = assetBundles[0] && assetBundles[0].id; // we support one bundle for now

  const onUpload = async e => {
    e.persist();
    const file = e.target;

    if (file) {
      addAsset({
        onBundleCreated,
        target: file,
        bundleId,
        relation: {
          companyId,
          relationEntity,
          relationId
        }
      })();
    }
  };

  useEffect(() => {
    const onMount = async () => {
      //todo add check when api is ready
      if (bundleId) {
        await findAssetsByBundleId({ id: bundleId, relationEntity })();
      }
    };

    onMount();

    return () => {
      assetBundlesStore.reset(relationEntity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationId]);

  useEffect(() => {
    if (validationErrors.assetSize) {
      message(errorMessagesMap.assetSize, "error");
    } else if (validationErrors.assetQuantity) {
      message(errorMessagesMap.assetQuantity, "error");
    } else if (validationErrors.assetName) {
      message(errorMessagesMap.assetName, "error");
    }
  }, [validationErrors]);

  return (
    <Div gap={40} paddingRight="25px">
      <Div direction="column" width="620px">
        <P size={16} weight="bold">
          Attachments
        </P>
        <P bottom={12} width="400px">
          Attach files to be associated with this {parentText}.
        </P>
        <UploadAsset onUpload={onUpload} disabled={disabled} />

        <Div top={12}>
          <AssetBundleList relationEntity={relationEntity} />
        </Div>
      </Div>
      <Div direction="column" paddingTop="6px">
        <InfoBox theme={themeStore.theme} width="358px" bottom={16}>
          Max file size is <BoldText>{maxAssetSizeMB}MB</BoldText>. You can add
          up to <BoldText>{maxAssetQty}</BoldText> files.
          <P top={6}>
            Please don't leave the page until all files are uploaded.
          </P>
        </InfoBox>
        <InfoBox theme={themeStore.theme} width="358px">
          Only super-admins may remove files attached by a super-admin and
          company-admin may only remove files attached by a company-admin.
        </InfoBox>
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore: rootStore,
    assetBundlesStore: rootStore.assetBundlesStore,
    themeStore: rootStore.themeStore
  })),
  observer
)(AssetBundle);
