import React from "react";
import { Checkbox } from "components/inputs";
import { dateFormatter } from "utils/format";

export const extensionTableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    align: "left",
    width: 200
  },
  {
    title: "Value",
    key: "value",
    dataIndex: "value",
    align: "center",
    width: 200
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    align: "center",
    width: 350
  }
  // {
  //   title: "Constraint",
  //   key: "constraint",
  //   dataIndex: "constraint",
  //   align: "center",
  //   width: 200,
  //   render: (_, row) => {
  //     const constraintMapping = {
  //       write: "Write",
  //       read: "Read"
  //     };
  //     return constraintMapping[row.constraint] || row.constraint;
  //   }
  // }
];

export const extensionHistoryTableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    align: "left",
    width: 100
  },
  {
    title: "Value",
    key: "value",
    dataIndex: "value",
    align: "center",
    width: 100
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    align: "center",
    width: 150
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    align: "center",
    width: 150,
    render: (_, row) => {
      return `${row.action}`;
    }
  },
  {
    title: "Details",
    key: "details",
    dataIndex: "details",
    align: "center",
    width: 250
  },
  {
    title: "Action By",
    key: "actionBy",
    dataIndex: "actionBy",
    align: "center",
    width: 200,
    render: (_, row) => {
      return row.actionBy;
    }
  },
  {
    title: "Date",
    key: "createdAt",
    dataIndex: "createdAt",
    align: "center",
    width: 200,
    render: (_, row) => dateFormatter(row.createdAt)
  }
];

export const permissionsTableColumns = (onChangePermission, permissions) => {
  return [
    {
      title: "Partner/Company name",
      key: "name",
      dataIndex: "name",
      align: "left",
      width: 300,
      render: (_, row) => {
        return `${row.name} (${row.type})`;
      }
    },
    {
      title: "Read",
      key: "read",
      dataIndex: "read",
      align: "center",
      render: (_, row) => {
        const isCompanyKey = row.type === "company";
        const checked = !!row.permissions.find((item) => item === "read");
        const indeterminate =  row.permissionsCount.read && row.extensionsCount !== row.permissionsCount.read;

        if (isCompanyKey) {
          return (
            <Checkbox large style={{justifyContent: "center"}} checked disabled />
          )
        }
        return (
          <Checkbox
            large
            indeterminate={indeterminate}
            style={{
              justifyContent: "center"
            }}
            checked={checked}
            onChange={() => {
              onChangePermission(row, "read")
            }}
          />
        )
      }
    },
    {
      title: "Write",
      key: "write",
      dataIndex: "write",
      align: "center",
      render: (_, row) => {
        const isCompanyKey = row.type === "company";
        const checked = !!row.permissions.find((item) => item === "write");
        const indeterminate = row.permissionsCount.write && row.extensionsCount !== row.permissionsCount.write;

        if (isCompanyKey) {
          return (
            <Checkbox large style={{justifyContent: "center"}} checked disabled />
          )
        }
        return (
          <Checkbox
            large
            indeterminate={indeterminate}
            style={{
              justifyContent: "center"
            }}
            checked={checked}
            onChange={() => {
              onChangePermission(row, "write")
            }}
          />
        )
      }
    },
  ]
}
