import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";

import { compose } from "utils/hoc";
import { Inner } from "components/Content";
import Div from "components/Div";
import { Select } from "components/inputs";
import Table from "components/Table";
import { useTablePaginator } from "components/hooks";
import HistorySearchForm from "components/forms/HistorySearch";
import { tagListTableColumns, extensionListTableColumns } from "./settings";

const SimpleSearch = ({
  tagsStore,
  extensionsStore,
  authStore,
  themeStore
}) => {
  // const [searchType, setSearchType] = useState("");
  const { tags, findAll: findAllTags, reset: resetTags } = tagsStore;
  const {
    extensions,
    findAll: findAllExtensions,
    reset: resetExtensions
  } = extensionsStore;
  const {
    searchType,
    setSearchType,
    setTagSearch,
    setTagSearchHistory,
    tagSearch,
    tagSearchHistory,
    setExtensionSearch,
    setExtensionSearchHistory,
    extensionSearch,
    extensionSearchHistory
  } = authStore;

  const [tagPagination, tagPaginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: paginationConfig => {
      findAllTags(null, paginationConfig, { search: tagSearch });
    }
  });

  const [extensionPagination, extensionPaginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: paginationConfig => {
      findAllExtensions({ search: extensionSearch }, paginationConfig);
    }
  });

  const delayedTagQuery = useRef(
    debounce(search => {
      tagPaginationReset();

      if (search) {
        setTagSearchHistory(search);
        findAllTags(null, tagPagination, { search });
      } else {
        resetTags();
      }
    }, 500)
  ).current;

  const delayedExtensionQuery = useRef(
    debounce(search => {
      extensionPaginationReset();

      if (search) {
        setExtensionSearchHistory(search);
        findAllExtensions({ search }, extensionPagination);
      } else {
        resetExtensions();
      }
    }, 500)
  ).current;

    // useEffect(() => {
    //   if (tagSearch && searchType === "tag") {
    //     setTagSearch("")
    //     resetTags();
    //     tagPaginationReset();
    //   }

    //   if (tagSearch && searchType === "extension") {
    //     setExtensionSearch("");
    //     resetExtensions();
    //     extensionPaginationReset();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchType, resetTags]);

  useEffect(() => {
    if (tagSearch && searchType === "tag") {
      findAllTags(null, tagPagination, { search: tagSearch });
    }

    return () => {
      resetTags();
      tagPaginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findAllTags, resetTags, searchType]);

  useEffect(() => {
    if (extensionSearch && searchType === "extension") {
      findAllExtensions({
        paginationOptions: extensionPagination,
        params: {
          search: extensionSearch
        }
      });
    }

    return () => {
      resetExtensions();
      extensionPaginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findAllTags, resetTags, searchType]);

  const onTagSearch = ({ value }) => {
    setTagSearch(value);
    delayedTagQuery(value);
  };

  const onExtensionSearch = ({ value }) => {
    setExtensionSearch(value);
    delayedExtensionQuery(value);
  };

  return (
    <>
      <Div justify="space-between">
        <Select
          inputWidth="400px"
          list={[
            {
              value: "tag",
              name: "Tag"
            },
            {
              value: "extension",
              name: "Extension"
            }
          ]}
          selectedValue={searchType}
          valueKey={"value"}
          optionKey={"value"}
          optionName={"name"}
          onChange={value => {
            setSearchType(value);
          }}
        />

        {searchType === "tag" && (
          <HistorySearchForm
            search={tagSearch}
            searchHistory={tagSearchHistory}
            onSearch={onTagSearch}
            placeholder="Search by tag id or manufacturer id"
            theme={themeStore.theme}
          />
        )}
        {searchType === "extension" && (
          <HistorySearchForm
            search={extensionSearch}
            searchHistory={extensionSearchHistory}
            onSearch={onExtensionSearch}
            placeholder="Search by extension name, value, id"
            theme={themeStore.theme}
          />
        )}
      </Div>
      <Inner>
        {searchType === "tag" && (
          <Table
            columns={tagListTableColumns}
            paginationConfig={tagPagination}
            data={tags}
          />
        )}
        {searchType === "extension" && (
          <Table
            columns={extensionListTableColumns}
            paginationConfig={extensionPagination}
            data={extensions}
          />
        )}
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    themeStore: rootStore.themeStore,
    authStore: rootStore.authStore,
    tagsStore: rootStore.tagsStore,
    extensionsStore: rootStore.extensionsStore
  })),
  observer
)(SimpleSearch);
