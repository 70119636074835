import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import {useModalWithValidation, useTablePaginator} from "components/hooks";
import { Button } from "components/actions";
import Modal from "components/Modal";
import Div from "components/Div";
import Table from "components/Table";
import ExtensionForm from "./ExtensionForm";
import { extensionTableColumns, extensionHistoryTableColumns, permissionsTableColumns } from "./settings"


const ExtensionList = ({
  companyId,
  rootStore,
  extensionsStore,
  apiKeysStore,
  extensions,
  extensionItem,
  resetExtension,
  setExtension,
  editExtension,
  deleteExtension,
  extensionsPagination
}) => {
  const [apiKeys, setApiKeys] = useState([]);
  const [initialApiKeys, setInitialApiKeys] = useState([]);
  const { findPermissions, savePermissions } = apiKeysStore;
  const { getHistory, history } = extensionsStore;
  const [historyPagination, paginationHistoryReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: (historyPaginationConfig) => {
      getHistory(extensionItem.id)(historyPaginationConfig);
    }
  });
  const [
    isEditModalShown,
    setEditModalShown,
    onEditModalOk,
    onEditModalCancel
  ] = useModalWithValidation(rootStore);
  const [
    isHistoryModalShown,
    setHistoryModalShown,
    onHistoryModalOk,
    onHistoryModalCancel
  ] = useModalWithValidation(rootStore);
  const [
    isPermissionModalShown,
    setPermissionModalShown,
    onPermissionModalOk,
    onPermissionModalCancel
  ] = useModalWithValidation(rootStore);

  const onChangePermission = (row, permission) => {
    const editApiKey = apiKeys.find((item) => item.id === row.id);
    let newPermissions = [];
    if (editApiKey.permissions.includes(permission)) {
      newPermissions = editApiKey.permissions.filter((item) => item !== permission)
    }
    if (!editApiKey.permissions.includes(permission)) {
      newPermissions = [...editApiKey.permissions, permission];
    }

    setApiKeys(apiKeys.map((item) => {
      if (item.id === row.id) {
        return {...item, permissions: newPermissions,};
      }
      return {...item};
    }));
  }

  const getChangedPermissions = () => {
    const permissions = []
    initialApiKeys.forEach((initialApiKey) => {
      const tableApiKey = apiKeys.find((item) => item.id === initialApiKey.id);
      if (tableApiKey.permissions.includes("read") && !initialApiKey.permissions.includes("read")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "read",
          action:  "add",
          relationEntity: "extension",
        })
      }
      if (!tableApiKey.permissions.includes("read") && initialApiKey.permissions.includes("read")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "read",
          action:  "delete",
          relationEntity: "extension",
        })
      }
      if (tableApiKey.permissions.includes("write") && !initialApiKey.permissions.includes("write")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "write",
          action:  "add",
          relationEntity: "extension",
        })
      }
      if (!tableApiKey.permissions.includes("write") && initialApiKey.permissions.includes("write")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "write",
          action:  "delete",
          relationEntity: "extension",
        })
      }
    })

    return permissions;
  }

  const renderActions = row => (
    <>
      <Button
        type="popoverRegular"
        onClick={setEditModalShown(() => {
          setExtension(row);
        })}
      >
        Edit
      </Button>
      <Button
        type="popoverRegular"
        onClick={setPermissionModalShown(async () => {
          const data = await findPermissions(companyId, row.id, {
            relationEntity: "extension",
          });
          const apiKeysData = data.data.map((item) => {
            return {
              relationId: row.id,
              ...item
            }
          })
          setApiKeys([...apiKeysData]);
          setInitialApiKeys([...apiKeysData]);
        })}
      >
        Permissions
      </Button>
      <Button
        type="popoverRegular"
        onClick={setHistoryModalShown(async () => {
          setExtension(row);
          await getHistory(row.id)(historyPagination);
        })}
      >
        History
      </Button>
      <Button
        type="popoverRedText"
        onClick={() => {
          deleteExtension(row.id);
        }}
      >
        Delete
      </Button>
    </>
  );

  return (
    <>
      <Modal
        width="1300px"
        title={`Extension history`}
        showModal={isHistoryModalShown}
        confirmButtonTitle="Close"
        onCancel={onHistoryModalCancel(paginationHistoryReset)}
      >
        <Table
          columns={extensionHistoryTableColumns}
          data={history}
          paginationConfig={historyPagination}
        />
      </Modal>
      <Modal
        title={`Extension permissions`}
        showModal={isPermissionModalShown}
        confirmButtonTitle="Save"
        onCancel={onPermissionModalCancel()}
        onConfirm={() => {
          onPermissionModalOk(async () => {
            let relationId = ''
            apiKeys.forEach((apiKey) => {
              relationId = apiKey.relationId;
            })

            await savePermissions(companyId, relationId, getChangedPermissions())
            return true
          })();
        }}
      >
        <Table
          columns={permissionsTableColumns(onChangePermission)}
          data={apiKeys}
          paginationConfig={false}
        />
      </Modal>
      <Modal
        title={`Please, edit an extension`}
        showModal={isEditModalShown}
        confirmButtonTitle="Save"
        onCancel={onEditModalCancel(resetExtension)}
        onConfirm={() => {
          const errors = rootStore.validator.validateExtension(extensionItem);
          if (rootStore.hasValidationErrors(errors)) return;
          onEditModalOk(async () => {
            await editExtension(extensionItem.id)
            return true
          })();
        }}
      >
        <Div width="60%">
          <ExtensionForm extension={extensionItem} />
        </Div>
      </Modal>
      <Table
        columns={extensionTableColumns}
        data={extensions}
        paginationConfig={extensionsPagination}
        renderActions={renderActions}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    extensionsStore: rootStore.extensionsStore,
    apiKeysStore: rootStore.apiKeysStore,
    rootStore
  })),
  observer
)(ExtensionList);
