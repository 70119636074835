import styled from "styled-components";
import { DatePicker } from 'antd';

// export const StyledDatePicker = styled(DatePicker)`
//   width: 100% !important;
//   height: 44px !important;
//   color: ${({ theme }) => theme.mainDarkColor} !important;
//   border: 1px solid
//     ${({ error, theme }) =>
//       `${
//         error === "true" ? theme.errorColor : theme.secondaryLightColor
//       }`} !important;
//   border-radius: ${({ theme }) => theme.borderRadius} !important;
//
//   &::-webkit-outer-spin-button,
//   &::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//   }
//
//   .ant-input[disabled] {
//     background-color: transparent !important;
//     border-color: transparent !important;
//     color: ${({ theme }) => theme.mainDarkColor} !important;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// `;


export const StyledDatePicker = styled(DatePicker)`
     width: 100% !important;
     height: 44px !important;

  .ant-calendar-picker-input.ant-input {
    height: 44px !important;
  }
`
