import { dateFormatter } from "utils/format";

export const tableColumns = [
  {
    title: "Partner/Company name",
    key: "name",
    dataIndex: "name",
    align: "left",
    width: 250
  },
  {
    title: "Key",
    key: "key",
    dataIndex: "key",
    align: "left",
    width: 300
  },
  // {
  //   title: "Address",
  //   key: "contactAddress",
  //   dataIndex: "contactAddress",
  //   align: "left",
  //   width: 200
  // },
  // {
  //   title: "Phone",
  //   key: "contactPhone",
  //   dataIndex: "contactPhone",
  //   align: "left",
  //   width: 200
  // },
  // {
  //   title: "Email",
  //   key: "contactEmail",
  //   dataIndex: "contactEmail",
  //   align: "left",
  //   width: 200
  // },
  {
    title: "Expires At",
    key: "expiresAt",
    dataIndex: "expiresAt",
    align: "left",
    width: 200,
    render: (_, row) => row.expiresAt ? dateFormatter(row.expiresAt) : ''
  },
  {
    title: "Created",
    key: "createdAt",
    dataIndex: "createdAt",
    align: "left",
    width: 200,
    render: (_, row) => dateFormatter(row.createdAt)
  },
];
