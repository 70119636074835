import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { debounce } from "lodash";

import { compose } from "utils/hoc";
import Dashboard from "../Dashboard";
import { useRefresh } from "../Dashboard/hooks";
import { columns } from "./settings";
import { useTablePaginator } from "../../../components/hooks";

const InterceptorDashboard = ({
  type,
  reason,
  lot,
  detailCard,
  interceptsStore
}) => {
  const [refresh, refreshData] = useRefresh();
  const [pagination, paginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: interceptsStore.onNextPage({ lotId: lot.id, type, reason })
  });

  useEffect(() => {
    const fetchIntercepts = async lot => {
      !lot?.id
        ? interceptsStore.reset()
        : debounce(
            async () =>
              await interceptsStore.findAll({ lotId: lot.id, type, reason }, pagination),
            600
          )();
    };

    fetchIntercepts(lot);
    return () => {
      interceptsStore.reset();
      paginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interceptsStore, type, reason, lot, refresh]);

  return (
    <Dashboard
      companyId={lot.company.id}
      pagination={pagination}
      mapConfig={lot?.configDiversion || { strategy: "", zones: [] }}
      detailCard={detailCard}
      columns={columns}
      items={interceptsStore.intercepts}
      onRefreshData={refreshData}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(InterceptorDashboard);
