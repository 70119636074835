import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Button } from "components/actions";
import Table from "components/Table";
import Div from "components/Div";
import Modal from "../../../components/Modal";
import EditApiKeyForm from "../../../features/companies/forms/EditApiKey";
import { useModal, useModalWithValidation } from "../../../components/hooks";
import { tableColumns } from "./settings.js";
function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const ApiList = ({ companyId, rootStore, match, apiKeysStore }) => {
  const { id } = match.params;
  const isEditRoute = !!id;
  const {
    setApiKey,
    apiKeys,
    apiKey,
    findAll,
    save,
    deleteById,
    resetApiKey
  } = apiKeysStore;
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOk,
    onDeleteModalCancel
  ] = useModal();
  const [
    isEditModalShown,
    setEditModalShown,
    onEditModalOk,
    onEditModalCancel
  ] = useModalWithValidation(rootStore);
  const [apiKeyId, setApiKeyId] = useState("");
  const [apiStatus, setApiStatus] = useState("Create");

  useEffect(() => {
    findAll()
  }, []);

  const renderActions = row => (
    <>
      <Button
        type="popoverRegular"
        onClick={setEditModalShown(() => {
          setApiKeyId(row.id)
          setApiStatus('update');
          const apiKey = apiKeys.find(apiKey => apiKey.id === row.id)
          setApiKey(apiKey)
        })}
      >
        Update
      </Button>
      <Button
        type="popoverRedText"
        onClick={setDeleteModalShown(() => {
          setApiKeyId(row.id)
        })}
      >
        Delete
      </Button>
    </>
  );
  return (
    <>
    <Modal
      title={`${capitalizeFirstLetter(apiStatus)} API Key`}
      showModal={isEditModalShown}
      confirmButtonTitle={apiStatus}
      onCancel={onEditModalCancel(resetApiKey)}
      onConfirm={() => {
        const errors = rootStore.validator.validateApiKey(apiKey);
        if (rootStore.hasValidationErrors(errors)) return;
        onEditModalOk(async () => {
          await save(!!apiKeyId)()
          await findAll()
          return true
        })();
      }}
    >
      <EditApiKeyForm />
    </Modal>
    <Modal
      title="Delete API Key"
      showModal={isDeleteModalShown}
      onCancel={onDeleteModalCancel(() => {
        setApiKeyId('')
      })}
      onConfirm={onDeleteModalOk(async () => {
        await deleteById(apiKeyId)()
        // await findAll()
      })}
    >
      {`Are you sure you want to delete API Key?`}
    </Modal>
      <Div bottom={25}>
        <Button
          type="purpleUnderlined"
          onClick={setEditModalShown(() => {
            resetApiKey()
            setApiKeyId('')
            setApiStatus('create');
          })}
          disabled={!isEditRoute}
        >
          + Create new
        </Button>
      </Div>
      <Table
        columns={tableColumns}
        data={apiKeys}
        paginationConfig={false}
        renderActions={renderActions}
      />
    </>
  )
}

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    apiKeysStore: rootStore.apiKeysStore
  })),
  observer
)(ApiList);
