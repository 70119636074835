import React from "react";

import { Link } from "components/actions";
import CompanyAdmin from "features/authorization/CompanyAdmin";

export const tableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    align: "left",
    width: 400,
    render: (_, { id, name }) => (
      <Link type="tableLink" to={`/companies/edit/${id}`}>
        {name}
      </Link>
    )
  },
  {
    title: "ID",
    key: "code",
    dataIndex: "code",
    align: "left",
  },
  {
    title: "Administrators",
    key: "companyUsers",
    dataIndex: "companyUsers",
    align: "center",
    render: (_, row) => <CompanyAdmin row={row} />
  },
  {
    title: "Rolls",
    key: "rolls",
    dataIndex: "rolls",
    align: "center",
    width: 100
  },
  {
    title: "Lots",
    key: "lots",
    dataIndex: "lots",
    align: "center",
    width: 100
  },
  {
    title: "Experiences",
    key: "experiences",
    dataIndex: "experiences",
    align: "center",
    width: 110
  }
];

export const accordionTitles = {
  details: "Details",
  contact:  "Contact",
  branding: "Branding",
  administrator: "Administrator",
  api: "API"
}
