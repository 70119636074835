import { observable, action, computed } from "mobx";
import Extension from "../models/Extension";

class Extensions {
  @observable rawExtensions = [];
  @observable rawExtension = {};
  @observable rawHistory = [];
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawExtension = this.populateExtension({}, rootStore);
  }

  @computed
  get extensions() {
    return this.rawExtensions;
  }

  @computed
  get history() {
    return this.rawHistory;
  }

  @computed
  get extension() {
    return this.rawExtension;
  }

  @action.bound setExtension(extension) {
    this.rawExtension = this.populateExtension(extension, this.rootStore);
  }

  populateExtension(extension, rootStore) {
    return new Extension(
      {
        ...extension
      },
      rootStore
    );
  }

  @action.bound excludeCurrent(extensionId) {
    return this.rawExtensions.filter(({ id }) => id !== extensionId);
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawExtensions = [];
    this.rawExtension = this.populateExtension({}, this.rootStore);
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value
    };
  }

  @action.bound onNextPage = config =>
    this.findAll({ paginationOptions: config });

  getPaginationParams(paginationConfig) {
    const paginationParams = paginationConfig && {
      limit: paginationConfig.limit,
      offset: paginationConfig.offset
    };

    return paginationParams || {};
  }

  @action.bound async findAll(params, paginationOptions) {
    const { method, url } = this.rootStore.urls.extensions.getAll;
    const paginationParams = this.getPaginationParams(paginationOptions);
    const requestParams = params || this.params;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: { ...requestParams, ...paginationParams }
    });

    if (response) {
      const { rows, count: itemsAmount } = response.data;
      if (paginationOptions?.isScrollPagination) {
        this.rawExtensions = [
          ...this.rawExtensions,
          ...rows.map(l => this.populateExtension(l, this.rootStore))
        ];
      } else {
        this.rawExtensions = rows.map(l =>
          this.populateExtension(l, this.rootStore)
        );
      }

      if (paginationOptions) {
        paginationOptions.setTotal(itemsAmount);
      }

      return { data: rows };
    }

    return response;
  }

  @action save = id => async () => {
    const { method, url } = id
      ? this.rootStore.urls.extensions.update
      : this.rootStore.urls.extensions.create;

    const body = id ? this.extension.updateData : this.extension.createData;
    const errors = this.rootStore.validator.validateExtension(body);

    if (this.rootStore.hasValidationErrors(errors)) return;

    const { response } = await this.rootStore.makeRequest({
      method,
      url: id ? `${url}/${id}` : `${url}`,
      body: id ? body : [body]
    });

    // if (response) {
    //   const extension = response.data;
    //   this.rawExtensions.push(
    //     this.populateExtension(extension, this.rootStore)
    //   );
    // }

    return response;
  };

  @action deleteById = id => async () => {
    const { method, url } = this.rootStore.urls.extensions.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    // if (response) {
    //   this.rawExtensions = this.rawExtensions.filter(l => l.id !== id);
    // }

    return response;
  };

  @action getHistory = id => async (paginationOptions) => {
    const { method, url } = this.rootStore.urls.extensions.getHistory;
    const paginationParams = this.getPaginationParams(paginationOptions);
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
      params: { ...paginationParams }
    });

    if (response) {
      const { rows, count: itemsAmount } = response.data;
      this.rawHistory = rows.map(l => l) ;

      if (paginationOptions) {
        paginationOptions.setTotal(itemsAmount);
      }

      return { data: rows };
    }

    return response;
  };

  @action getRelationHistory = id => async (paginationOptions) => {
    const { method, url } = this.rootStore.urls.extensions.getRelationHistory;
    const paginationParams = this.getPaginationParams(paginationOptions);
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
      params: { ...paginationParams }
    });

    if (response) {
      const { rows, count: itemsAmount } = response.data;
      this.rawHistory = rows.map(l => l) ;

      if (paginationOptions) {
        paginationOptions.setTotal(itemsAmount);
      }

      return { data: rows };
    }

    return response;
  };
}

export default Extensions;
