import { observable, action, computed, toJS } from "mobx";

import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = ["description"];

class Roll extends DirtyState {
  @observable description = "";
  @observable assetBundles = [];

  @observable includeTags = false;
  @observable includeQRCodes = false;
  @observable qrCodeType = "simple";

  constructor(roll, rootStore) {
    super(PATH_LIST_TO_OBSERVE, roll);

    this.rootStore = rootStore;
    Object.assign(this, roll);
  }

  @computed
  get updateData() {
    return toJS({
      description: this.description,
    });
  }

  @action.bound async change(event) {
    this[event.target.name] = event.target.value;
  }

  @action.bound onChangeIncludeTags() {
    this.includeTags = !this.includeTags;
  }

  @action.bound onIncludeQRCodes() {
    this.includeQRCodes = !this.includeQRCodes;
  }

  @action.bound onQRCodeTypeSelect(value) {
    this.qrCodeType = value;
  }
}

export default Roll;
