import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Text, DatePicker, Select } from "components/inputs";
import Form from "components/Form";

const EditApiKey = ({
  apiKeysStore: { apiKey },
  rootStore: { validationErrors }
}) => (
  <Form direction="column" width="60%">
    <Div bottom={25}>
      <Select
        label="Type"
        list={[
          { name: "User", value: "user" },
          { name: "Company", value: "company" },
        ]}
        valueKey="value"
        optionKey="value"
        selectedValue={apiKey.type}
        error={validationErrors.type}
        errorMessage="Type is required"
        onChange={apiKey.changeType}
        required
      />
    </Div>
    <Div bottom={25}>
      <Text
        label="Partner/Company name"
        value={apiKey.name}
        name="name"
        error={validationErrors.name}
        errorMessage="Must be more than 1 and less than 64 symbols"
        onChange={apiKey.change}
        required
      />
    </Div>
    <Div bottom={25}>
      <Text
        label="Contact email"
        value={apiKey.contactEmail}
        name="contactEmail"
        maxLength={64}
        error={validationErrors.contactEmail}
        errorMessage="Must be valid email."
        onChange={apiKey.change}
      />
    </Div>
    <Div bottom={25}>
      <Text
        label="Contact firts name"
        value={apiKey.contactFirstName}
        name="contactFirstName"
        maxLength={64}
        onChange={apiKey.change}
      />
    </Div>
    <Div bottom={25}>
      <Text
        label="Contact last name"
        value={apiKey.contactLastName}
        name="contactLastName"
        maxLength={64}
        onChange={apiKey.change}
      />
    </Div>
    <Div bottom={25}>
      <Text
        label="Contact phone"
        value={apiKey.contactPhone}
        name="contactPhone"
        onChange={apiKey.change}
      />
    </Div>
    <Div bottom={25}>
      <Text
        label="Contact address"
        value={apiKey.contactAddress}
        name="contactAddress"
        maxLength={250}
        onChange={apiKey.change}
      />
    </Div>
    <Div bottom={25}>
      <DatePicker
        label="Expires at"
        name="expiresAt"
        value={apiKey.expiresAt}
        error={validationErrors.expiresAt}
        errorMessage="Must be in future"
        onChange={apiKey.changeExpiresAt}
      />
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    apiKeysStore: rootStore.apiKeysStore,
    rootStore
  })),
  observer
)(EditApiKey);
