import { observable, action, computed } from "mobx";

import ApiKey from "../models/ApiKey";

class ApiKeys {
  @observable rawApiKeys = [];
  @observable rawApiKey = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawApiKey = new ApiKey({}, rootStore);
  }

  @computed
  get apiKeys() {
    return this.rawApiKeys;
  }

  @computed
  get apiKey() {
    return this.rawApiKey;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawApiKeys = [];
    this.rawApiKey = new ApiKey({}, this.rootStore);
    this.rootStore.resetValidationErrors();
  }

  @action.bound resetApiKey() {
    this.rawApiKey = new ApiKey({}, this.rootStore);
    this.rootStore.resetValidationErrors();
  }

  @action.bound setApiKey(apiKey) {
    this.rawApiKey = new ApiKey(apiKey, this.rootStore);
  }

  @action.bound async findAll(companyId) {
    const id = companyId || this.rootStore.companiesStore.company.id
    const { method, url } = this.rootStore.urls.apiKeys.getAll;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`,
    });

    if (response) {
      this.rawApiKeys = response.data.map((u) => new ApiKey(u, this.rootStore));
    }

    return response;
  }

  @action.bound async findPermissions(companyId, relationId, params) {
    const { method, url } = this.rootStore.urls.apiKeys.getPermissions;

    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${companyId}/permissions/${relationId}`,
      params: { ...params }
    });

    return response
  }

  @action.bound async savePermissions(companyId, relationId, body, params = {}) {
    const { method, url } = this.rootStore.urls.apiKeys.savePermissions;

    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${companyId}/permissions/${relationId}`,
      params: { ...params },
      body
    });

    return response
  }

  @action save = (isEdit) => async () => {
    const { method, url } = isEdit
      ? this.rootStore.urls.apiKeys.update
      : this.rootStore.urls.apiKeys.create;

    const body = isEdit ? this.apiKey.updateData : this.apiKey.createData;
    const errors = this.rootStore.validator.validateApiKey(body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${this.rootStore.companiesStore.company.id}`,
      body
    });

    if (response) {
      return response.data;
    }
  };

  @action deleteById = (id) => async () => {
    const { method, url } = this.rootStore.urls.apiKeys.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    if (response) {
      this.rawApiKeys = this.rawApiKeys.filter(l => l.id !== id);
    }

    return response;
  };
}

export default ApiKeys;
