import { observable, action } from "mobx";
import keys from "lodash/keys";

import { message } from "utils/format";
import ls from "utils/local-storage";
import api from "../services/api";
import urls from "../services/api-urls";
import validator from "../services/validator";
import Templates from "./Templates";
import Experiences from "./Experiences";
import Companies from "./Companies";
import Users from "./Users";
import Auth from "./Auth";
import Lots from "./Lots";
import Rolls from "./Rolls";
import SubRolls from "./SubRolls";
import Tags from "./Tags";
import Extensions from "./Extensions";
import Sidebar from "./Sidebar";
import Tableau from "./Tableau";
import Constraints from "./Constraints";
import Theme from "./Theme";
import Intercepts from "./Intercepts";
import Activations from "./Activations";
import CloudEndpoints from "./CloudEndpoints";
import AssetBundles from "./AssetBundles";
import ApiKeys from "./ApiKeys";

const {
  REACT_APP_LS_TOKEN_KEY,
  REACT_APP_LS_CONTEXT_COMPANY_KEY,
  REACT_APP_SIDEBAR_STATE_KEY
} = process.env;

export class RootStore {
  urls = urls;
  api = api;
  validator = validator;
  message = message;
  @observable pending = false;
  @observable validationErrors = {};
  @observable appVersion = "";
  @observable serverVersion = "";

  constructor(routingStore) {
    this.routingStore = routingStore;
    this.templatesStore = new Templates(this);
    this.experiencesStore = new Experiences(this);
    this.companiesStore = new Companies(this);
    this.usersStore = new Users(this);
    this.authStore = new Auth(this);
    this.lotsStore = new Lots(this);
    this.rollsStore = new Rolls(this);
    this.subRollsStore = new SubRolls(this);
    this.tagsStore = new Tags(this);
    this.extensionsStore = new Extensions(this);
    this.sidebarStore = new Sidebar(this);
    this.tableauStore = new Tableau(this);
    this.constraintsStore = new Constraints(this);
    this.themeStore = new Theme(this);
    this.diversionsStore = new Intercepts(this);
    this.counterfeitsStore = new Intercepts(this);
    this.activationsStore = new Activations(this);
    this.tracesStore = new Activations(this);
    this.cloudEndpointsStore = new CloudEndpoints(this);
    this.assetBundlesStore = new AssetBundles(this);
    this.apiKeysStore = new ApiKeys(this);
  }

  @action.bound hasValidationErrors(errors) {
    this.validationErrors = errors;
    return !!keys(errors).length;
  }

  @action.bound resetValidationErrors() {
    this.validationErrors = {};
  }

  @action.bound async makeRequest({
    method,
    url,
    body,
    headers,
    params,
    useExternalUrl,
    responseType
  }) {
    this.pending = true;

    const { data: response } = await this.api.sendRequest({
      method,
      url,
      data: method === "get" || method === "delete" ? {} : body,
      headers,
      params,
      useExternalUrl,
      responseType
    });

    setTimeout(() => (this.pending = false), 300);

    return { response, body };
  }

  abortRequest() {
    this.api.abort();
  }

  @action.bound async initializer() {
    const {
      data: { app, serverVersion }
    } = await this.api.getApiVersion();
    this.appVersion = app;
    this.serverVersion = serverVersion;

    if (await ls.get(REACT_APP_LS_TOKEN_KEY)) {
      await this.authStore.getProfile();
    }

    this.authStore.contextCompany = await ls.get(
      REACT_APP_LS_CONTEXT_COMPANY_KEY,
      {}
    );

    this.sidebarStore.state = await ls.get(REACT_APP_SIDEBAR_STATE_KEY);
  }
}

export default RootStore;
