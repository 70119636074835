import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import { Header } from "components/Content";
import { compose } from "utils/hoc";
import { Button } from "components/actions";
import { Tabs, Tab } from "components/Tabs";
import Div from "components/Div";
import { rollInfoOptions } from "./settings";
import Summary from "features/miscellany/Summary";
import AssetBundle from "features/miscellany/AssetBundle";
import EditRollForm from "features/rolls/forms/EditRoll";
import withCancelModal from "components/withCancelModal";
import Extensions from "features/rolls/Extensions";

const Edit = ({ rollsStore, extensionsStore, match, onLeavePage }) => {
  const [activeTab, setActiveTab] = useState("#details");
  const { roll, save, findById } = rollsStore;
  const { rollId } = match.params;
  const onSave = () => save(rollId);

  useEffect(() => {
    const onMount = async () => {
      if (rollId) {
        await findById({ id: rollId })();
      }
    };

    onMount();

    return () => {
      rollsStore.reset();
    };
  }, [rollsStore, findById, rollId]);

  const handleTabChange = key => {
    setActiveTab(key);
  };

  return (
    <>
      <Header
        title="Edit Roll"
        backButton
        backButtonFn={onLeavePage(roll.isDirty)}
        backButtonLink="/rolls"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button type="regularFlat" onClick={onLeavePage(roll.isDirty)}>
              Cancel
            </Button>
            <Button type="regular" onClick={onSave}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <Tab tab="Details" key="#details">
          <Div
            paddingTop="25px"
            paddingLeft="25px"
            paddingBottom="100px"
            direction="column"
            gap={25}
          >
            <Div direction="column">
              <Summary
                rows={rollInfoOptions.map(({ key, title }) => ({
                  title,
                  value: roll[key]
                }))}
              />
              <EditRollForm />
            </Div>
            <Div direction="column" gap={25}>
              <Div>
                <AssetBundle
                  companyId={roll.company && roll.company.id}
                  assetBundles={roll.assetBundles}
                  relationEntity="roll"
                  relationId={roll.id}
                  parentText="roll"
                />
              </Div>
            </Div>
          </Div>
        </Tab>
        <Tab tab="Extensions" key="#extensions">
          <Extensions relationEntity="roll" relationId={rollId} companyId={roll?.company?.id}></Extensions>
        </Tab>
      </Tabs>
    </>
  );
};

export default compose(
  withCancelModal("/rolls"),
  inject(({ rootStore }) => ({
    rollsStore: rootStore.rollsStore,
    extensionsStore: rootStore.extensionsStore,
    rootStore
  })),
  observer
)(Edit);
