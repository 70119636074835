import React from "react";
import { inject, observer } from "mobx-react";
import moment from 'moment';

import { compose } from "utils/hoc";
import { StyledDatePicker } from "./Styled";
import { StyledInputWrapper } from "../common/Styled";
import Label from "../Label";
import ErrorMessage from "../../ErrorMessage";
import Div from "../../Div";

const DatePicker = ({
  label = "",
  labelTextSize,
  value = "",
  placeholder,
  index = 0,
  name = "",
  disabled,
  error = false,
  errorMessage = "",
  horizontalLabel = false,
  required = false,
  suffixIcon,
  infoPopover = false,
  infoPopoverContent = "",
  infoPopoverPlacement = "right",
  themeStore,
  onChange = () => null,
}) => {
  return (
    <StyledInputWrapper horizontalLabel={horizontalLabel}>
      {label && (
         <Label
           text={label}
           horizontalLabel={horizontalLabel}
           textSize={labelTextSize}
           required={required}
           infoPopover={infoPopover}
           infoPopoverContent={infoPopoverContent}
           infoPopoverPlacement={infoPopoverPlacement}
         />
       )}
      <Div display="block" width="100%">
        <StyledDatePicker
          popupStyle={{
            zIndex: 1200
          }}
          style={{
            height: "44px",
            width: "100%",
          }}
          defaultValue={value ? moment(value) : null}
          disabledDate={(date) => {
            return date < moment(new Date());
          }}
          format="YYYY/MM/DD"
          placeholder={placeholder}
          data-index={index}
          name={name}
          disabled={disabled}
          error={String(error)}
          suffix={suffixIcon}
          theme={themeStore.theme}
          onChange={onChange}
        />
        {error && <ErrorMessage text={errorMessage} />}
      </Div>
    </StyledInputWrapper>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
  })),
  observer
)(DatePicker);
