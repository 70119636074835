import React from "react";
import { Popover } from "antd";

import { dateFormatter } from "utils/format";
import { Link } from "../../components/actions";

export const tagListTableColumns = [
  {
    title: "Tag ID",
    key: "tag",
    dataIndex: "id",
    render: (_, { id }) => (
      <Link type="tableLink" to={`/tags/optics/${id}`}>
        {id}
      </Link>
    )
  },
  {
    title: "Manufacturer ID",
    key: "manufacturerId",
    dataIndex: "manufacturerId"
  },
  {
    title: "Lot name",
    key: "lot",
    dataIndex: "lot.name",
    render: (_, { lot }) => {
      if (lot) {
        return (
          <Link type="tableLink" to={`/lots/edit/${lot.id}`}>
            {lot.name}
          </Link>
        );
      }
    }
  },
  {
    title: "Roll",
    key: "roll",
    dataIndex: "roll.id",
    render: (_, { roll }) => {
      if (roll) {
        const content = (
          <div>
            <div>Description: {roll.description || "-"}</div>
            <div>Tag manufacturer: {roll.tagManufacturer || "-"}</div>
            <div>Tag model: {roll.tagModel || "-"}</div>
          </div>
        );

        return (
          <Link type="tableLink" to={`/rolls/edit/${roll.id}`}>
            <Popover content={content} width="auto" offset={0} justify="center">
              {roll.id}
            </Popover>
          </Link>
        );
      }
    }
  },
  {
    title: "Created",
    key: "created-at",
    dataIndex: "createdAt",
    align: "center",
    render: (_, row) => dateFormatter(row.createdAt),
    width: "24ch"
  }
];

export const extensionListTableColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name"
  },
  {
    title: "Value",
    key: "value",
    dataIndex: "value"
  },
  {
    title: "Relation",
    key: "relationEntity",
    dataIndex: "relationEntity",
    width: "150"
  },
  {
    title: "Relation ID",
    key: "relationId",
    dataIndex: "relationId",
    width: 350,
    render: (_, { id, relationEntity, relationId }) => {
      const routeMapping = {
        tag: `/tags/optics/${relationId}`,
        roll: `/rolls/edit/${relationId}`,
        lot: `/lots/edit/${relationId}`
      };
      return (
        <Link type="tableLink" to={routeMapping[relationEntity]}>
          {relationId}
        </Link>
      );
    }
  },
  {
    title: "Date",
    key: "created-at",
    dataIndex: "createdAt",
    align: "center",
    render: (_, row) => dateFormatter(row.createdAt),
    width: "24ch"
  }
];
