import { observable, action, computed } from "mobx";
import { omit } from "lodash";

import Roll from "../models/Roll";

class Rolls {
  @observable rawRolls = [];
  @observable rawRoll = {};
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawRoll = new Roll({}, rootStore);
  }

  @computed
  get rolls() {
    return this.rawRolls;
  }

  @computed
  get roll() {
    return this.rawRoll;
  }

  @computed
  get onlyCompanyRolls() {
    return this.rawRolls.filter(
      r => this.rootStore.authStore.contextCompanyId === r.company.id
    );
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawRolls = [];
    this.rawRoll = new Roll({}, this.rootStore);
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value
    };
  }

  @action.bound removeFromParams(key) {
    const newParams = omit(this.params, key);
    this.params = {
      ...newParams
    };
  }

  @action.bound onNextPage = config =>
    this.findAll({paginationOptions: config});

  getPaginationParams(paginationConfig) {
    const paginationParams = paginationConfig && {
      limit: paginationConfig.limit,
      offset: paginationConfig.offset
    };

    return paginationParams || {};
  }

  @action.bound async findAll({ params, paginationOptions, filterOptions }) {
    const { method, url } = this.rootStore.urls.rolls.getAll;
    const requestParams = params || this.params;
    const paginationParams = this.getPaginationParams(paginationOptions);

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: this.rootStore.authStore.isInContext
        ? {
            ...requestParams,
            ...paginationParams,
            companyId: this.rootStore.authStore.contextCompanyId
          }
        : {
            ...requestParams,
            ...paginationParams
          }
    });

    if (response) {
      const { rows, count: itemsAmount } = response.data;
      if (paginationOptions?.isScrollPagination) {
        this.rawRolls = [...this.rawRolls, ...rows.map(r => new Roll(r, this.rootStore))];
      } else {
        this.rawRolls = rows.map(r => new Roll(r, this.rootStore));
      }

      if (paginationOptions) {
        paginationOptions.setTotal(itemsAmount);
      }

      return { data: rows };
    }

    return response;
  }

  @action findById = ({ id }) => async () => {
    const { method, url } = this.rootStore.urls.rolls.getByRollId;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    if (response) {
      this.rawRoll = new Roll(
        { ...response.data },
        this.rootStore
      );
    }

    return response;
  };

  @action.bound async save(id) {
    const { method, url } = this.rootStore.urls.rolls.update;

    const errors = this.rootStore.validator.validateRoll(this.roll.updateData);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: { ...this.roll.updateData, id }
    });

    if (response) {
      this.rootStore.routingStore.push(`/rolls`);
    }

    return response;
  }

  @action.bound async assignToCompany(company) {
    const errors = await this.rootStore.validator.validateCompanyAssign(
      company
    );
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { method, url } = this.rootStore.urls.rolls.update;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: {
        id: this.roll.id,
        companyId: company.id
      }
    });

    this.rawRolls = this.rawRolls.map(roll => {
      if (roll.id === this.roll.id) {
        return { ...roll, company };
      }

      return roll;
    });

    return response;
  }

  @action generateRollData = async () => {
    const { method, url } = this.rootStore.urls.rolls.export;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: {
        id: this.roll.id,
        includeTags: this.roll.includeTags,
        includeQRCodes: this.roll.includeQRCodes,
        qrCodeType: this.roll.qrCodeType
      },
      responseType: "blob"
    });

    return response;
  };

  @action exportRollData = async () => {
    const response = await this.generateRollData();

    if (response) {
      const fileURL = window.URL.createObjectURL(new Blob([response]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      const fileName = "roll-data.zip";
      fileLink.setAttribute("download", fileName);
      fileLink.setAttribute("target", "_blank");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    }

    return response;
  };
}

export default Rolls;
