import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import { Text } from "components/inputs";
import Form from "components/Form";

const ExtensionForm = ({ extension, rootStore }) => {
  const { validationErrors } = rootStore;

  return (
    <Form direction="column" width="100%">
      <Div bottom={25}>
        <Text
          label="Name"
          value={extension?.name}
          name="name"
          error={validationErrors.name}
          errorMessage="Must be more than 1 and less than 64 symbols. Must be be unique"
          onChange={extension?.change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Value"
          value={extension?.value}
          name="value"
          error={validationErrors?.value}
          errorMessage="Must be more than 1 and less than 256 symbols"
          onChange={extension.change}
          required
        />
      </Div>
      <Div bottom={25}>
        <Text
          label="Description"
          value={extension?.description}
          name="description"
          error={validationErrors?.description}
          errorMessage="Must be less than 256 symbols"
          onChange={extension.change}
        />
      </Div>
      {/* <Div bottom={25}>
        <Select
          label="Constraint"
          list={[
            {
              value: "read",
              name: "Read"
            },
            {
              value: "write",
              name: "Write"
            }
          ]}
          selectedValue={extension.constraint}
          valueKey={"value"}
          optionKey={"value"}
          optionName={"name"}
          onChange={extension.changeConstraint}
        />
      </Div> */}
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore: rootStore
  })),
  observer
)(ExtensionForm);
