import _ from "lodash";
import { observable, action, computed, toJS } from "mobx";

import Experience from "./Experience";
import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "name",
  "description",
  "status",
  "experience.id",
  "interceptDiversion",
  "configDiversion.notifyEmail",
  "configDiversion.notifyEmailAddress",
  "configDiversion.strategy",
  "configDiversion.zones",
  "configCounterfeit.rejectUrl"
];

const DIVERSION_DEFAULT = {
  notifyEmail: false,
  notifyEmailAddress: "",
  strategy: "exclusion",
  zones: []
};

const COUNTERFEIT_DEFAULT = {
  notifyEmail: false,
  notifyEmailAddress: "",
  matchTagManufacturerID: false,
  matchTagCounter: false,
  matchTagDistanceTime: false
};

const ADVANCED_CONFIG_DEFAULT = {
  customOpenStateExperience: false,
  customClosedStateExperience: false,
};

class Lot extends DirtyState {
  @observable id = "";
  @observable name = "";
  @observable description = "";
  @observable status = "inactive";
  @observable interceptDiversion = false;
  @observable configDiversion = {};
  @observable interceptCounterfeit = false;
  @observable configCounterfeit = {};
  @observable configAdvanced = {};
  @observable notifierIds = [];
  @observable experience = {};
  @observable company = {};
  @observable assetBundles = [];
  @observable createdAt = "";
  @observable nfcTags = 0;
  @observable qrcTags = 0;
  tags = 0;

  constructor(lot, rootStore) {
    super(PATH_LIST_TO_OBSERVE, lot);

    this.rootStore = rootStore;
    Object.assign(this, lot);

    if (_.isEmpty(this.configDiversion)) {
      Object.assign(this.configDiversion, DIVERSION_DEFAULT);
    }

    if (_.isEmpty(this.configCounterfeit)) {
      Object.assign(this.configCounterfeit, COUNTERFEIT_DEFAULT);
    }

    if (!this.configAdvanced || _.isEmpty(this.configAdvanced)) {
      if (!this.configAdvanced) {
        this.configAdvanced = ADVANCED_CONFIG_DEFAULT
      }
      Object.assign(this.configAdvanced, ADVANCED_CONFIG_DEFAULT);
    }
  }

  @computed
  get selectedExperienceKeys() {
    return this.experience ? [this.experience.id] : [];
  }

  @computed
  get createData() {
    // todo: create common mapper for this
    let base =
      this.experience && this.experience.id
        ? { experienceId: this.experience.id }
        : {};
    base = this.status ? { ...base, status: this.status } : base;

    return toJS({
      name: this.name,
      description: this.description,
      interceptDiversion: this.interceptDiversion,
      configDiversion: this.configDiversion,
      interceptCounterfeit: this.interceptCounterfeit,
      configCounterfeit: this.configCounterfeit,
      configAdvanced: this.configAdvanced,
      notifierIds: this.notifierIds,
      ...base
    });
  }

  @computed
  get updateData() {
    let base =
      this.experience && this.experience.id
        ? { experienceId: this.experience.id }
        : { experienceId: null };
    base = this.status ? { ...base, status: this.status } : base;

    return toJS({
      lotId: this.id,
      name: this.name,
      description: this.description,
      interceptDiversion: this.interceptDiversion,
      configDiversion: this.configDiversion,
      interceptCounterfeit: this.interceptCounterfeit,
      configAdvanced: this.configAdvanced,
      configCounterfeit: this.configCounterfeit,
      notifierIds: this.notifierIds,
      ...base
    });
  }

  @action.bound async change(event) {
    _.set(this, event.target.name, event.target.value);
  }

  @action.bound async assignExperience(raw) {
    const { id, type } = raw || this.experience;
    this.experience = await this.rootStore.experiencesStore.findById({
      id,
      type: type.name
    })();
  }

  @action.bound async changeLotStatus(status) {
    this.status = status;
  }

  @action.bound async changeTagsQty(tagType) {
    const tagName = `${tagType}Tags`;
    this[tagName] = this[tagName] - 1;
    this.tags = this.tags - 1;
  }

  @action.bound async removeExperience() {
    this.experience = new Experience({}, this.rootStore);
  }
}

export default Lot;
