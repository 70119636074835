import { observable, action, computed, toJS } from "mobx";

import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "name",
  "type",
  "contactEmail",
  "contactFirstName",
  "contactLastName",
  "contactPhone",
  "contactAddress",
  "expiresAt"
];

class ApiKey extends DirtyState {
  @observable id = "";
  @observable type = "";
  @observable name = "";
  @observable contactEmail = "";
  @observable contactFirstName = "";
  @observable contactLastName = "";
  @observable contactPhone = "";
  @observable contactAddress = "";
  @observable expiresAt = "";

  constructor(apiKey, rootStore) {
    super(PATH_LIST_TO_OBSERVE, apiKey);

    this.rootStore = rootStore;
    Object.assign(this, apiKey);
  }

  @computed
  get createData() {
    return toJS({
      name: this.name,
      type: this.type,
      contactEmail: this.contactEmail || null,
      contactFirstName: this.contactFirstName || null,
      contactLastName: this.contactLastName || null,
      contactPhone: this.contactPhone || null,
      contactAddress: this.contactAddress || null,
      expiresAt: new Date(this.expiresAt) || null
    });
  }

  @computed
  get updateData() {
    return toJS({
      id: this.id,
      type: this.type,
      name: this.name,
      contactEmail: this.contactEmail,
      contactFirstName: this.contactFirstName || null,
      contactLastName: this.contactLastName || null,
      contactPhone: this.contactPhone,
      contactAddress: this.contactAddress,
      expiresAt: this.expiresAt
    });
  }

  @action.bound async change(event) {
    this[event.target.name] = event.target.value;
  }

  @action.bound async changeType(value) {
    this.type = value;
  }

  @action.bound async changeExpiresAt(value) {
    this.expiresAt = value;
  }
}

export default ApiKey;
