import {observable} from "mobx";

class Tag {
  @observable id = "";
  @observable assetBundles = [];
  constructor(tag, rootStore) {
    this.rootStore = rootStore;
    Object.assign(this, tag);
  }
}

export default Tag;
