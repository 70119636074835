import React, {useState} from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Modal from "components/Modal";
import Div from "components/Div";
import P from "components/P";
import { Button } from "components/actions";
import { useModalWithValidation } from "components/hooks";
import ExtensionList from "./ExtensionList";
import ExtensionForm from "./ExtensionForm";
import Table from "../../../components/Table";
import {permissionsTableColumns} from "./settings";

const Extension = ({
  companyId,
  rootStore,
  apiKeysStore,
  extensions,
  extensionItem,
  relationEntity,
  relationId,
  resetExtension,
  setExtension,
  addExtension,
  editExtension,
  deleteExtension,
  pagination
}) => {
  const [apiKeys, setApiKeys] = useState([]);
  const [initialApiKeys, setInitialApiKeys] = useState([]);
  const { findPermissions, savePermissions } = apiKeysStore;
  const [
    isEditModalShown,
    setEditModalShown,
    onEditModalOk,
    onEditModalCancel
  ] = useModalWithValidation(rootStore);
  const [
    isPermissionModalShown,
    setPermissionModalShown,
    onPermissionModalOk,
    onPermissionModalCancel
  ] = useModalWithValidation(rootStore);

  const onChangePermission = (row, permission) => {
    const editApiKey = apiKeys.find((item) => item.id === row.id);
    let newPermissions = [];
    if (editApiKey.permissions.includes(permission)) {
      newPermissions = editApiKey.permissions.filter((item) => item !== permission)
    }
    if (!editApiKey.permissions.includes(permission)) {
      newPermissions = [...editApiKey.permissions, permission];
    }

    const  { [permission]: _, ...rest } =  editApiKey.permissionsCount;
    // editApiKey.permissionsCount = {...rest}
    const newPermissionsCount = {...rest}

    setApiKeys(apiKeys.map((item) => {
      if (item.id === row.id) {
        return {...item, permissions: newPermissions, permissionsCount: newPermissionsCount};
      }
      return {...item};
    }));
  }

  const getChangedPermissions = () => {
    const permissions = []
    initialApiKeys.forEach((initialApiKey) => {
      const tableApiKey = apiKeys.find((item) => item.id === initialApiKey.id);
      const tablePermissionCountKeys = Object.keys(tableApiKey.permissionsCount);
      const initialPermissionCountKeys = Object.keys(initialApiKey.permissionsCount);
      if (tableApiKey.permissions.includes("read") && !initialApiKey.permissions.includes("read")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "read",
          action:  "add",
          relationEntity: "extension",
        })
      }
      if (!tableApiKey.permissions.includes("read") && initialApiKey.permissions.includes("read")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "read",
          action:  "delete",
          relationEntity: "extension",
        })
      }
      if (tableApiKey.permissions.includes("write") && !initialApiKey.permissions.includes("write")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "write",
          action:  "add",
          relationEntity: "extension",
        })
      }
      if (!tableApiKey.permissions.includes("write") && initialApiKey.permissions.includes("write")) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "write",
          action:  "delete",
          relationEntity: "extension",
        })
      }
      if (!tablePermissionCountKeys.includes("read")
        && !tableApiKey.permissions.includes("read")
        && initialApiKey.permissionsCount.read
      ) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "read",
          action:  "delete",
          relationEntity: "extension",
        })
      }
      if (!tablePermissionCountKeys.includes("write")
        && !tableApiKey.permissions.includes("write")
        && initialApiKey.permissionsCount.write
      ) {
        permissions.push({
          apiKeyId: initialApiKey.id,
          apiKeyName: initialApiKey.name,
          permission: "write",
          action:  "delete",
          relationEntity: "extension",
        })
      }
    })

    return permissions;
  }

  return (
    <Div>
      <Modal
        title={`Please, create an extension`}
        showModal={isEditModalShown}
        confirmButtonTitle="Add"
        onCancel={onEditModalCancel(resetExtension)}
        onConfirm={() => {
          const errors = rootStore.validator.validateExtension(extensionItem);
          if (rootStore.hasValidationErrors(errors)) return;
          onEditModalOk(addExtension)();
        }}
      >
        <Div width="60%">
          <ExtensionForm extension={extensionItem} />
        </Div>
      </Modal>
      <Modal
        title={`Extension permissions`}
        showModal={isPermissionModalShown}
        confirmButtonTitle="Save"
        onCancel={onPermissionModalCancel()}
        onConfirm={() => {
          onPermissionModalOk(async () => {
            await savePermissions(companyId, '', getChangedPermissions(), {
              permissionRelationEntity: "extension",
              extensionRelationEntity: relationEntity,
              extensionRelationId: relationId
            })
            return true
          })();
        }}
      >
        <Table
          columns={permissionsTableColumns(onChangePermission)}
          data={apiKeys}
          paginationConfig={false}
        />
      </Modal>
      <Div direction="column">
        <Div justify="space-between" key="action-buttons" align="center">
          <P width="400px">
            Add extension to be associated with this {relationEntity}.
          </P>
          <Div>
            <Button type="regular" onClick={setPermissionModalShown(async () => {
              const data = await findPermissions(companyId, '', {
                permissionRelationEntity: "extension",
                extensionRelationEntity: relationEntity,
                extensionRelationId: relationId
              });
              setApiKeys([...data.data]);
              setInitialApiKeys([...data.data]);
            })} styles={{marginRight: 12}}>
              Permissions
            </Button>
            <Button type="regular" onClick={setEditModalShown(() => {})}>
              Add extension
            </Button>
          </Div>
        </Div>

        <Div top={12}>
          <ExtensionList
            companyId={companyId}
            extensions={extensions}
            extensionItem={extensionItem}
            resetExtension={resetExtension}
            editExtension={editExtension}
            setExtension={setExtension}
            deleteExtension={deleteExtension}
            pagination={pagination}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore: rootStore,
    extensionsStore: rootStore.extensionsStore,
    apiKeysStore: rootStore.apiKeysStore,
  })),
  observer
)(Extension);
