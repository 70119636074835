import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { useTablePaginator } from "components/hooks";
import Extension from "features/miscellany/Extension";

const Extensions = ({ extensionsStore, relationEntity, relationId, companyId }) => {
  const {
    extensions,
    extension,
    findAll,
    setExtension,
    save,
    deleteById,
    reset
  } = extensionsStore;
  const [pagination, paginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: (paginationConfig) => {
      findAll({
        relationEntity: relationEntity,
        relationId: relationId
      }, paginationConfig);
    }
  });

  const getExtensions = () => {
    findAll({
      relationEntity: relationEntity,
      relationId: relationId
    }, pagination)
  };

  useEffect(() => {
    getExtensions();
    return () => {
      reset();
      paginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Extension
      companyId={companyId}
      relationEntity={relationEntity}
      relationId={relationId}
      extensions={extensions}
      extensionItem={extension}
      setExtension={setExtension}
      resetExtension={() => {
        setExtension({});
      }}
      addExtension={async () => {
        setExtension({
          ...extension,
          relationEntity: relationEntity,
          relationId: relationId
        });
        await save()();
        getExtensions();
        setExtension({});
        return true;
      }}
      editExtension={async id => {
        await save(id)();
        getExtensions();
        setExtension({});
        return true;
      }}
      deleteExtension={async id => {
        await deleteById(id)();
        await getExtensions();
        return true;
      }}
      extensionsPagination={pagination}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    extensionsStore: rootStore.extensionsStore,
    rootStore
  })),
  observer
)(Extensions);
