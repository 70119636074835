import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { debounce } from "lodash";

import { compose } from "utils/hoc";
import Dashboard from "../Dashboard";
import { useRefresh } from "../Dashboard/hooks";
import { columns } from "./settings";
import { useTablePaginator } from "../../../components/hooks";

const ActivationDashboard = ({
  lot,
  tagId,
  detailListView,
  detailCard,
  store
}) => {
  const [refresh, refreshData] = useRefresh();
  const [pagination, paginationReset] = useTablePaginator({
    isScrollPagination: true,
    pageSize: 10,
    limit: 10,
    onPageChange: (paginationOptions) => {
      store.findAll({ lotId: lot.id, tagId }, paginationOptions);
    }
  });

  useEffect(() => {
    const fetchActivations = async () => {
      !lot?.id
        ? store.reset()
        : debounce(
            async () => await store.findAll({ lotId: lot.id, tagId }, pagination),
            600
          )();
    };

    fetchActivations();
    return () => {
      store.reset();
      paginationReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, lot, tagId, refresh]);

  const activationColumns = !tagId
    ? columns
    : columns.filter(({ dataIndex }) => dataIndex !== "tagId");

  const configDiversion = lot?.configDiversion && Object.keys(lot?.configDiversion).length ? lot?.configDiversion : { strategy: "", zones: [] }

  return (
    <Dashboard
      companyId={lot?.company?.id}
      pagination={pagination}
      mapConfig={configDiversion}
      detailCard={detailCard}
      detailListView={detailListView}
      columns={activationColumns}
      items={store.activations}
      onRefreshData={refreshData}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(ActivationDashboard);
