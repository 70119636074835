import _ from "lodash";
import { observable, action, computed, toJS } from "mobx";

import DirtyState from "./DirtyState";

const PATH_LIST_TO_OBSERVE = [
  "name",
  "description",
  "value",
  "constraint",
  "constraint"
];

class Extension extends DirtyState {
  @observable id = "";
  @observable name = "";
  @observable value = "";
  @observable description = "";
  @observable relationEntity = "";
  @observable relationId = "";
  @observable constraint = "";

  constructor(extension, rootStore) {
    super(PATH_LIST_TO_OBSERVE, extension);

    this.rootStore = rootStore;
    Object.assign(this, extension);
  }

  @computed
  get createData() {
    return toJS({
      name: this.name,
      description: this.description,
      value: this.value,
      relationEntity: this.relationEntity,
      relationId: this.relationId,
      constraint: this.constraint
    });
  }

  @computed
  get updateData() {
    return toJS({
      id: this.id,
      name: this.name,
      description: this.description,
      value: this.value,
      constraint: this.constraint
    });
  }

  @action.bound async change(event) {
    _.set(this, event.target.name, event.target.value);
  }

  @action.bound async changeConstraint(constraint) {
    this.constraint = constraint;
  }
}

export default Extension;
