import { message as antdMessage } from "antd";

export const dateFormatter = (d) => new Date(d).toLocaleString(undefined);

export const utc2tz = (d, tz) =>
  new Date(
    (typeof d === "string" ? new Date(d) : d).toLocaleString(undefined, {
      timeZone: tz
    })
  );

export const message = (text = "", type = "info", config = {}) => {
  antdMessage.config({
    top: 100,
    maxCount: 1,
    ...config
  });

  antdMessage[type](text);
};

export const removeNonNumericCharacters = string => string.replace(/\D/g, "");

export const filesToFormData = (input, fieldNames) => {
  const formData = new FormData();
  fieldNames.forEach((name, i) => formData.append(name, input.files[i]));
  input.value = "";
  return formData;
};

export const dateFormatMMMMDDYYYY = (date) => {
  const newDate = new Date(date);

  return newDate.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const formatMilliseconds = (ms) => {
  // 1. Input validation
  if (typeof ms !== 'number' || ms < 0 || !Number.isFinite(ms)) {
    // Handle invalid input (optional: throw an error instead)
    console.error("Invalid input: Milliseconds must be a non-negative finite number.");
    return "00:00:00"; // Or throw new Error(...), or return null/undefined
  }

  // 2. Calculate total seconds (discard milliseconds part)
  const totalSeconds = Math.floor(ms / 1000);

  // 3. Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // 4. Pad each component with leading zeros if needed
  const paddedHours = String(hours).padStart(2, '0');
  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');

  // 5. Combine into HH:MM:SS format
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}
